import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import Product from "../../containers/product/Product";

class EcommercePage extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="projects-main">
        <Header
          theme={theme}
          seoTitle={"E-handel | Jonas Lundholm"}
          seoDescription={
            "Jonas Lundholm | Experienced Software Developer and Freelancer from Stockholm, Sweden"
          }
        />
        <Product
          theme={this.props.theme}
          backgroundColor={this.props.theme.secondaryBackgroundColor}
          titleTextColor={"black"}
          textColor={"#555555"}
          linkTextColor={"#555555"}
        />
        <Footer theme={theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default EcommercePage;
