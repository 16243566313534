import React, { Component } from "react";
import "./ProductSection.css";
import { offerings, copy } from "../../portfolio";
import OfferingItem from "./OfferingItem";

class ProductSection extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="product-section-container">
        <div className="product-section-text-container">
          <span
            style={{ color: this.props.titleTextColor }}
            className="product-section-title"
          >
            {copy.get("productSectionTitle")}
          </span>
          {/* <span className="product-section-subtitle">
            {copy.get("helloSectionSubtitle")}
          </span> */}
        </div>

        {offerings.data.map((project) => {
          return (
            <>
              {project.imageAlign === "left" && (
                <OfferingItem
                  theme={theme}
                  titleTextColor={this.props.titleTextColor}
                  linkTextColor={this.props.linkTextColor}
                  key={project.id}
                  project={project}
                />
              )}
              {project.imageAlign === "right" && (
                <OfferingItem
                  theme={theme}
                  titleTextColor={this.props.titleTextColor}
                  linkTextColor={this.props.linkTextColor}
                  key={project.id}
                  project={project}
                />
              )}
            </>
          );
        })}
      </div>
    );
  }
}

export default ProductSection;
