import React from "react";
import "./Product.css";
import ProductSection from "./ProductSection.js";
import ContactMeButton from "../../components/contactMeButton/ContactMeButton";
import { copy } from "../../portfolio";

function Product(props) {
  return (
    <div
      className="product-container"
      style={{
        backgroundColor: props.backgroundColor,
        color: props.textColor,
        fontFamily: props.font,
      }}
    >
      <ProductSection
        theme={props.theme}
        titleTextColor={props.titleTextColor}
        linkTextColor={props.linkTextColor}
      />

      <div className="product-worktogether-description">
        {/* <div className="worktogether-description" style={{ color: theme.text }}> */}
        <p>{copy.get("callToActionEcommerceTitle")}</p>

        <ContactMeButton
          textColor={"white"}
          backgroundColor={"#A145FF"}
          hoverBackgroundColor={"#6C1BD9"}
          className={"contact-me-button-div"}
          text={copy.get("callToActionButtonTitle")}
          theme={props.theme}
        />
      </div>
    </div>
  );
}

export default Product;
