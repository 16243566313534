import Cookies from "js-cookie";

const languageCookieName = "language";
const cookieConsentName = "cookie-consent";
const CONSENT_COOKIE_EXPIRY_IN_DAYS = 7;

const consentToCookies = () => {
  Cookies.set(cookieConsentName, "true", {
    expires: CONSENT_COOKIE_EXPIRY_IN_DAYS,
  });
};

const declineCookies = () => {
  Cookies.remove(languageCookieName);
  Cookies.set(cookieConsentName, "false", {
    expires: CONSENT_COOKIE_EXPIRY_IN_DAYS,
  });
};

const hasUserConsentededToCookies = () => {
  const cookieConsent = Cookies.get(cookieConsentName);

  if (cookieConsent === undefined) {
    return undefined;
  }
  return cookieConsent === "true" ? true : false;
};

const changeLanguage = () => {
  const currentLanguage = getCurrentLanguage();
  if (currentLanguage === "en") {
    setLanguage("se");
    return "se";
  } else {
    setLanguage("en");
    return "en";
  }
};

const getCurrentLanguage = () => {
  return 'en'
};

const setLanguage = (language) => {
  localStorage.setItem(languageCookieName, language);
};

export {
  changeLanguage,
  consentToCookies,
  declineCookies,
  getCurrentLanguage,
  hasUserConsentededToCookies,
  setLanguage,
};
