import React from "react";
import "./Footer.css";
import { Fade } from "react-reveal";
/* eslint-disable jsx-a11y/accessible-emoji */

export default function Footer(props) {
  const backgroundColor = props.backgroundColor;
  const textColor = props.textColor;
  return (
    <Fade>
      <div
        className="footer-container"
        style={{ backgroundColor: backgroundColor, color: textColor }}
      >
        <div className="footer-top-container">
          <div className="footer-links-container">
            <div className="footer-links-name-container">
              <a href={`/privacy-policy`} className="footer-links-text">Privacy Policy</a>
            </div>
            <div className="footer-links-name-container">
              <a href={`/terms-conditions`} className="footer-links-text">Terms and Conditions</a>
            </div>
          </div>

        </div>
      </div>
    </Fade>
  );
}
