import React, { Component } from "react";
import "./OfferingItem.css";
import { Fade } from "react-reveal";
import Image from "../../components/image/Image.js";
import { getCurrentLanguage } from "../../util/util";
const language = getCurrentLanguage();

class OfferingItem extends Component {
  render() {
    const project = this.props.project;

    const workTextClassName =
      project.imageAlign === "left"
        ? "hello-item-text-div-left"
        : "hello-item-text-div-right";

    const imageStyle = { borderRadius: "1.8rem" };

    return (
      <div className="hello-item-main-div">
        {project.imageAlign === "left" && (
          <div className="hello-item-image-div-left">

            <Fade duration={2000} top>
              <Image style={imageStyle} src={project.imageLink} />
            </Fade>
            <span
              className="hello-item-heading-left-mobile"
              style={{ color: this.props.titleTextColor }}
            >
              {/* For mobile we want the project heading to show up after the image*/}
              {project.title}
            </span>
            
          </div>
        )}

        {project.imageAlign === "left" && (
          <div className={workTextClassName}>
            <span
              className="hello-item-heading-left-desktop"
              style={{ color: this.props.titleTextColor }}
            >
              {/* For desktop we want the project heading to show up after the image*/}
              {project.title}
            </span>
            <Fade>
              <div
                className="hello-item-description-mobile"
                dangerouslySetInnerHTML={{
                  __html: project.descriptionHtml[language],
                }}
              ></div>
              <div className="hello-item-url-mobile-container">
                <a
                  style={{ color: this.props.linkTextColor }}
                  className="hello-item-url"
                  href={project.url}
                >
                  {project.url}
                </a>
              </div>
              <div
                className="hello-item-description-desktop"
                dangerouslySetInnerHTML={{
                  __html: project.descriptionHtml[language],
                }}
              ></div>
              <div className="hello-item-url-desktop-container">
                <a
                  style={{ color: this.props.linkTextColor }}
                  className="hello-item-url"
                  href={project.url}
                >
                  {project.url}
                </a>
              </div>
            </Fade>
          </div>
        )}

        {project.imageAlign === "right" && (
          <div className={workTextClassName}>
            <span
              className="hello-item-heading-right-desktop"
              style={{ color: this.props.titleTextColor }}
            >
              {/* For desktop we want the project heading to show up after the image*/}
              {project.title}
            </span>
            <Fade>
              <div
                className="hello-item-description-desktop"
                dangerouslySetInnerHTML={{
                  __html: project.descriptionHtml[language],
                }}
              ></div>
              <div className="hello-item-url-desktop-container">
                <a
                  style={{ color: this.props.linkTextColor }}
                  className="hello-item-url"
                  href={project.url}
                >
                  {project.url}
                </a>
              </div>
            </Fade>
          </div>
        )}

        {project.imageAlign === "right" && (
          <div className="hello-item-image-div-right-mobile">
            <Fade duration={2000} top>
              <Image style={imageStyle} src={project.imageLink} />
            </Fade>
          
            <span
              className="hello-item-heading-right-mobile"
              style={{ color: this.props.titleTextColor }}
            >
              {/* For desktop we want the project heading to show up after the image*/}
              {project.title}
            </span>
            <Fade>
              <div
                className="hello-item-description-mobile"
                dangerouslySetInnerHTML={{
                  __html: project.descriptionHtml[language],
                }}
              ></div>
              <div className="hello-item-url-mobile-container">
                <a
                  style={{ color: this.props.linkTextColor }}
                  className="hello-item-url"
                  href={project.url}
                >
                  {project.url}
                </a>
              </div>
            </Fade>
          </div>
        )}
      </div>
    );
  }
}

export default OfferingItem;
