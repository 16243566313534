import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../pages/home/HomeComponent";
import Contact from "../pages/contact/ContactComponent";
import ProjectsPage from "../pages/projects/ProjectsPage";
import EcommercePage from "../pages/eCommerce/EcommercePage";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";
import AboutMePage from "../pages/aboutMe/AboutMePage";
import PrivacyPolicyPage from "../pages/privacy/PrivacyPolicyPage";
import TermsAndConditionsPage from "../pages/termsAndConditions/TermsAndConditionsPage";
import DeleteMyDataPage from "../pages/account/DeleteMyDataPage.js";

export default class Main extends Component {
  render() {
    return (
      <>
        <BrowserRouter basename="/">
          <Switch>
            <Route
              path="/"
              exact
              render={(props) => <Home {...props} theme={this.props.theme} />}
            />
            <Route
              path="/home"
              render={(props) => <Home {...props} theme={this.props.theme} />}
            />
            <Route
              path="/contact"
              render={(props) => (
                <Contact {...props} theme={this.props.theme} />
              )}
            />

            <Route
              path="/projects"
              render={(props) => (
                <ProjectsPage {...props} theme={this.props.theme} />
              )}
            />
            <Route
              path="/projekt"
              render={(props) => (
                <ProjectsPage {...props} theme={this.props.theme} />
              )}
            />

            <Route
              path="/about-me"
              render={(props) => (
                <AboutMePage {...props} theme={this.props.theme} />
              )}
            />
            <Route
              path="/om-mig"
              render={(props) => (
                <AboutMePage {...props} theme={this.props.theme} />
              )}
            />
            <Route
              path="/e-handel"
              render={(props) => (
                <EcommercePage {...props} theme={this.props.theme} />
              )}
            />
            <Route
              path="/privacy-policy"
              render={(props) => (
                <PrivacyPolicyPage {...props} theme={this.props.theme} />
              )}
            />
            <Route
              path="/terms-conditions"
              render={(props) => (
                <TermsAndConditionsPage {...props} theme={this.props.theme} />
              )}
            />
            <Route
              path="/delete-my-data"
              render={(props) => (
                <DeleteMyDataPage {...props} theme={this.props.theme} />
              )}
            />
            {/* <Route
              path="/cookies"
              render={(props) => (
                <CookiePage {...props} theme={this.props.theme} />
              )}
            /> */}
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}
