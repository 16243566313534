import React, { Component } from "react";
import Header from "../../components/header/Header";
import Greeting from "../../containers/greeting/Greeting";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";

class Home extends Component {
  conso;
  render() {
    return (
      <div>
        <Header
          theme={this.props.theme}
          seoTitle={"Tidy - Simplify your life"}
          seoDescription={
            "Jonas Lundholm is an experienced Freelance Software Developer focusing on building websites using modern technologies and great web design. With over 5 years in the industry I've worked with multiple successful startups."
          }
        />
        {/* {hasUserConsentededToCookies() === undefined && <CookieNotice/>} */}
        <Greeting theme={this.props.theme} />
        {/* <Hello
          theme={this.props.theme}
          backgroundColor={this.props.theme.secondaryBackgroundColor}
          titleTextColor={"black"}
          textColor={"#555555"}
          linkTextColor={"#555555"}
        />
        <WorkTogether theme={this.props.theme}></WorkTogether> */}
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Home;
