import React, { Component } from "react";
import Header from "../../components/header/Header";

class DeleteMyDataPage extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="projects-main">
        <Header
          theme={theme}
          seoTitle={"Privacy Policy | Tidy"}
          seoDescription={
            "Privacy policy for Tidy"
          }
        />
        <div style={{ maxWidth: '90%', margin: '0 auto', display: "flex", flexDirection: 'column'}}>
          <div> 
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
              <h1>
                Delete my data
              </h1>
              <span style={{ marginBottom: '0.5rem'}}>If you'd like your account to be deleted, send an email to </span>
              <a    style={{ marginBottom: '0.5rem'}}
                    href={`mailto:tidy-app@jlundholm.com`}
                    className={`email-style`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    tidy-app@jlundholm.com
                    {/* <span></span> */}
                </a>
                <span> from the email account you'd like to be deleted. </span>
            </div>         
          </div>
        </div>
      </div>
    );
  }
}

export default DeleteMyDataPage;
