import React from "react";
import "./Greeting.css";
import { app, copy } from "../../portfolio";
import Image from "../../components/image/Image.js";
import { Fade } from "react-reveal";
import ProductSection from "../product/ProductSection";


const imageStyle = { borderRadius: "1.8rem" };

export default function Greeting(props) {
  const theme = props.theme;
  return (
    <div>
      <div className="greet-main">
        <div className="greeting-main">
          <Fade top duration={1000}>
            <div className="greeting-image-container">
              <Image style={imageStyle} src={app.logoLink} />
            </div>
          </Fade>
          <Fade top duration={500}>
          <div className="greeting-container">
            
            <span className="greeting-text-title" style={{ color: theme.text }}>
              {copy.get("homePageTitle")}
            </span>
            <ProductSection/>
            {/* <span
              className="greeting-text-subtitle"
              style={{
                color: theme.secondaryText,
              }}
            >
              {copy.get("homePageSubtitle")}
            </span> */}
            {/* <div className="button-greeting-div">
              <Button text="Contact me" href="#contact" />
              <Button text="See my resume" newTab={true} href={greeting.resumeLink} />
            </div> */}
          </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}
